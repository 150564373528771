import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from './config'; // Import config for videoRoomId

function Call() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const timestampParam = Number(params.get('code').substring(0, 13)); // Extract first 13 digits and convert to number

    // console.log(timestampParam);
    const currentTime = new Date().getTime();
    console.log('current', currentTime);
    // const oneHourLater = timestampParam + 3600000; // Add 1 hour in milliseconds
    const oneHourLater = timestampParam + 400000; // Add 1 hour in milliseconds
    // const oneHourLater = timestampParam + 10000; // Add 1 hour in milliseconds
    // console.log('oneHourLater', oneHourLater)
    var diff = oneHourLater - currentTime
    console.log('diff', diff)


    useEffect(() => {
        const idleTimer = setTimeout(() => {
            window.location.reload(); // Refresh the page after 1 minute if idle
        }, 400000); // 1 minute in milliseconds

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(idleTimer);
    }, []);

    const handleCloseWindow = () => {
        window.close(); // Closes the window
    };


    if (timestampParam && diff >= 0) {
        return (
            <div>
                <iframe
                    src={`https://datavan.whereby.com/${config.videoRoomId}?bottomToolbar=off&lang=zh-hant&settingsButton=off&topToolbar=off&aec=on`}
                    allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                    style={{ height: '700px', width: '100%' }}
                    frameBorder="0"
                    title="Whereby Video Call"
                ></iframe>
                {/* Large X icon for closing window */}
                <div
                    onClick={handleCloseWindow}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        fontSize: '60px',
                        color: 'black', // Change text color to black
                        cursor: 'pointer',
                        background: 'lightgrey', // Change background to white
                        borderRadius: '5px', // Small border radius for square-like appearance
                        padding: '10px',
                        width: '60px', // Fixed width for the square
                        height: '60px', // Fixed height for the square
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' // Optional shadow for better visibility
                    }}
                >
                    &times;
                </div>


            </div>
        );
    } else {
        return <div>Expired or invalid link.</div>;
    }
}

export default Call;
