import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from './config'; // Import config

function Test() {
    const [officeContacts, setOfficeContacts] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading

    // Fetch officeContacts data from the API
    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get(
                    config.nocodbApiUrl, // Use the nocodbApiUrl from config
                    {
                        headers: {
                            'xc-token': config.nocodbApiToken, // Use the nocodbApiToken from config
                        },
                    }
                );

                // Map through the response to ensure department_representative is converted to boolean
                const modifiedContacts = response.data.list.map((contact) => ({
                    ...contact,
                    department_representative: contact.department_representative === 'True', // Convert to boolean
                }));

                // Set the office contacts data
                setOfficeContacts(modifiedContacts);
                setLoading(false); // Turn off loading when data is fetched
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false); // Turn off loading even in case of error
            }
        };

        fetchContacts();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Display loading text while fetching
    }

    return (
        <div className="container">
            <h2>Office Contacts</h2>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Department</th>
                        <th>Title</th>
                        <th>Chinese Name</th>
                        <th>English Name</th>
                        <th>Phone</th>
                        <th>Department Representative</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {officeContacts.map((contact) => (
                        <tr key={contact.Id}>
                            <td>{contact.Id}</td>
                            <td>{contact.department}</td>
                            <td>{contact.title}</td>
                            <td>{contact.chinese_name}</td>
                            <td>{contact.english_name}</td>
                            <td>{contact.phone}</td>
                            <td>{contact.department_representative ? 'Yes' : 'No'}</td>
                            <td>{new Date(contact.CreatedAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Test;
